import React, { useState, useEffect } from "react";
import { useMedia } from "use-media";
import Layout from "../../../components/chat-channel/Layout";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Container from "../../../components/chat-channel/Container";
import TitleAndMetaTags from "../../../components/common/TitleAndHeader";
import { RequestForm } from "../../../components/form";
import { OnScrollPopup } from "../hr-chatbot";
import { GoToPopup } from "../../homepage";
import { CookiesPoup } from "../../homepage";
import {
  cta_1,
  cta_1_mob,
  cta_2,
  cta_2_mob,
  ExistingBlogCta,
} from "./4-reasons-to-use-ms-teams-as-a-digital-helpdesk";
import { redirectToWithUtm } from "../../../components/Header";
import "react-accessible-accordion/dist/fancy-example.css";

const topImage = require("../../../assets/images/market_ui/goto_testimonial_cta.png");

const BlogHeader = require("../../../assets/img/blogs/header_blog_reasons_why.png.webp");

const ldSchema = {
  "@context": "https://schema.org",
  "@type": "BlogPosting",
  mainEntityOfPage: {
    "@type": "WebPage",
    "@id":
      "https://workativ.com/conversational-ai-platform/slack-chatbot-guide",
  },
  headline: "Slack Chatbot Guide for your Business",
  image: "",
  author: {
    "@type": "Organization",
    name: "Team Workativ",
  },
  publisher: {
    "@type": "Organization",
    name: "Workativ",
    logo: {
      "@type": "ImageObject",
      url:
        "https://workativ.com/static/workativ-logo-5eea21f2dcd20bbbbf52debb41c0f26e.png",
    },
  },
  datePublished: "2021-08-05",
  dateModified: "2021-08-05",
};

export default function FeatureHomePage() {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Reasons why AI will enhance; not replace support agents"
        description="AI and Automation has brought about considerable changes in simple and complex workplace processes for quicker output."
        keywords={[
          "Slack Chatbot, Automation, AI Chatbot",
          "workativ free trial",
          "Slack chatbot free trial",
          "Slack Chatbot",
        ]}
        ogImage={BlogHeader}
        schema={ldSchema}
      />
      <section className="market_main_wrapper">
        <Container>
          <Layout backgroundColor={"_bg_market"} logoFor="ASSISTANT">
            <div className="grid_container_chatbot">
              <div className="container">
                <div className="row">
                  <div className="grid_container_chatbot_wrapper">
                    <div className="grid__wrapper">
                      <div className="col-lg-12 col-md-12 col-xs-12 trial_header_left">
                        <div className="main-slider-left-market">
                          <h1 className="font-page-header ">
                            Reasons why AI will enhance; not replace support
                            agents
                          </h1>
                        </div>
                      </div>
                      <img src={BlogHeader} />
                      <div className=" mt-5 market_wrapper_page">
                        <p class="font-section-normal-text line-height-2">
                          In the last decade, automation has brought about
                          considerable changes in simple and complex processes
                          for quicker output. In the call center industry,
                          programmed automation has enhanced customer experience
                          and made a significant impact on support efficiency.
                          With{" "}
                          <a href="https://blog.workativ.com/artificial-intelligence-ai-whats-next/?utm_source=blog&utm_medium=organic&utm_campaign=website%20blogs&utm_term=internal&utm_content=Reasons%20why%20AI%20will%20enhance%3B%20not%20replace%20support%20agents">
                            artificial intelligence
                          </a>{" "}
                          being at the forefront of change, there is
                          apprehension over whether AI will take over end-user
                          support completely. Although AI aims to make systems
                          more intelligent, it cannot replace the human element
                          in support and instead must be used in harmony with
                          agents. Here we will throw light on how AI will
                          enhance the work of agents without trying to replace
                          them.
                        </p>
                        <h2 className="font-section-sub-header-small-bold">
                          Streamline basic inquiries
                        </h2>
                        <p class="font-section-normal-text line-height-2">
                          With modern contact center solutions, it is easy to
                          streamline the process of basic inquiry may be through
                          an IVR where customers are redirected to the
                          appropriate department’s agent through an automated
                          system. For the user, this change translates to higher
                          customer satisfaction, whereas, for the service
                          provider, this means greater efficiency of work.
                        </p>
                        <p class="font-section-normal-text line-height-2">
                          Irrespective of the domain of service, most contact
                          centers receive repetitive questions or requests
                          daily, and{" "}
                          <a href="https://workativ.com/conversational-ai-platform/conversational-ai-chatbot">
                            AI can be used to deliver responses to such queries.{" "}
                          </a>
                          At the first point of contact, AI can bring down the
                          customer response time, thereby aiding call centers in
                          maintaining their service level agreements.
                        </p>
                      </div>

                      <NocodeWrapper />

                      <div>
                        <h6 className="font-section-sub-header-small-bold">
                          Understanding where AI works best and where it fails
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          The basic concept of AI is such that it takes in
                          data(often in large quantities) and then learns how to
                          interact with it. Humans have to program it with a
                          specific set of knowledge to enable performing a given
                          task.
                        </p>

                        <p class="font-section-normal-text line-height-2">
                          To understand this better in the context of contact
                          centers, let us consider an example. A contact center
                          may have a bot that delivers a group of relevant
                          answers as responses to the customer’s questions. The
                          logic here is that it identifies specific keywords in
                          the issues, and based on them, groups and presents the
                          most appropriate answers. However, there is an
                          infinite number of questions possible with a given
                          keyword. Forcing the user to choose from a bunch of
                          related answers will lead to customer dissatisfaction.
                          An ideal course of action will be where the customer
                          types in a question and based on the keywords; the AI
                          system will give a specific answer. If he is not
                          satisfied with the response, he will be led to a human
                          agent. The seamless handoff is a win-win situation for
                          everyone as it ensures customer satisfaction while
                          ensuring workplace efficiency for contact centers.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          Human intervention for more complex situations
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Technological advancements bring with them a host of
                          complex problems that require human intervention for
                          prompt resolution. Leading global contact centers see
                          better handling of complex issues through agents
                          rather than a machine. As per a report(Time), the
                          average hold time for calls handled by automated
                          systems in KPI(a leading global contact center) is 1
                          min 51 sec. The average values for live agents
                          handling a call stand at 51-sec. It is clear to see
                          that AI systems are best for handling repetitive and
                          straightforward technical issues, leaving the complex
                          issues for humans to take up. Based on the performance
                          of AI, it would not harm businesses to assign L2 and
                          L3 tickets in the future, only if they do not require
                          human intervention.
                        </p>

                        <h6 className="font-section-sub-header-small-bold">
                          Communication requires the human touch
                        </h6>
                        <p class="font-section-normal-text line-height-2">
                          Any form of communication relies heavily on the
                          emotional response of the two people involved. For
                          support that is entirely satisfactory to the end-user,
                          the customer service industry would do well to
                          maintain a balance in the communication from man and
                          machine. Although efforts are made to make responses
                          more natural and conversational using AI, a good
                          number of millennials admit preferring human
                          interaction over intelligent automated live chat
                          during lengthy conversations with support.{" "}
                          <a href="https://workativ.com/conversational-ai-platform/innovative-technology">
                            Enhancements with AI
                          </a>{" "}
                          can only be used where tasks are routine. AI does not
                          have the understanding of emotion as a science and,
                          therefore,{" "}
                          <a href="https://blog.workativ.com/reasons-why-ai-will-enhance-not-replace-support-agents/">
                            cannot be empowered to replace humans
                          </a>{" "}
                          for every form of communication.
                        </p>
                      </div>
                      <ExistingBlogCta
                        ContentCta="Auto-resolve 60% of Your Employee Queries With Generative AI Chatbot & Automation."
                        ButtonText="Book a Demo"
                        isColor="black"
                        backgroundImage={cta_1}
                        mobileBackgroundImage={cta_1_mob}
                      />
                      <div className="most_popular_links">
                        <div className="most_popular_header font-section-sub-header-bold">
                          Related Articles
                        </div>
                        <div className="most_popular_ul">
                          <ul
                            className="section__ul"
                            style={{ width: "100%", float: "left" }}
                          >
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/for-it-helpdesk-proper-process-for-remote-working-is-the-need-of-the-hour-in-the-time-of-covid-19">
                                For IT Helpdesk: Proper process for remote
                                working is the need of the hour in the time of
                                COVID-19
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/5-help-desk-tasks-everyone-should-automate">
                                {" "}
                                5 Help Desk Tasks Everyone Should Automate
                              </a>
                            </li>
                            <li>
                              <a href="https://workativ.com/conversational-ai-platform/effective-call-deflection-with-ai-automation-to-reduce-helpdesk-costs">
                                Effective call deflection with AI + Automation
                                to reduce Helpdesk costs
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="button_last">
                        <button>
                          <a href="https://blog.workativ.com/category/conversational-ai">
                            Conversational AI
                          </a>
                        </button>
                      </div>
                    </div>
                    <div className="sticky_right_img">
                      <a href="/conversational-ai-platform/case-studies/goto-implements-chatbot-for-it-helpdesk-automation">
                        {" "}
                        <img src={topImage} alt="goto testimonial cta" />{" "}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* {isSmall ? null : <OnScrollPopup />} */}
            {/* <GoToPopup />
            <CookiesPoup /> */}
            <RequestForm isFooterForm={true} />
          </Layout>
        </Container>
      </section>
    </>
  );
}

const NocodeWrapper = () => {
  return (
    <div className="nocode_wrapper mt-0 ">
      <h4>No Code - Free Conversational AI Chatbot </h4>
      <button>
        <a
          href="https://assistant.workativ.com/authentication/u/direct-signup"
          alt="Conversational AI chatbot"
        >
          Get Started
        </a>
      </button>
    </div>
  );
};
